import './App.css';
import React, {useEffect, useRef, useState} from 'react';

import Button from "./asset/Button";
import Header from "./asset/Header";
import Section from "./asset/Section";
import Cards from "./asset/Cards";
import Experience from "./asset/Experience";
import Selector from "./asset/Selector";
import Footer from "./asset/Footer";
import CustomAlert from "./asset/CustomAlert";
import CustomCursor from "./asset/CustomCursor";
import Gallery from "./asset/Gallery";

import barNoir from './img/bar-noir.svg';
import whiteArrow from './img/white-arrow.svg';
import experiences from './asset/resources/experience';

import en from './asset/translations/en-translation';
import fr from './asset/translations/fr-translation';



function App() {
    const getInitialLanguage = () => {
        const savedLanguage = localStorage.getItem('language');
        return savedLanguage === 'fr' ? fr : en;
    };

    const [language, setLanguage] = useState(getInitialLanguage);
    const [selectedExperience, setSelectedExperience] = useState(language.projet[0].experiences[0]);

    const toggleLanguage = () => {
        const newLanguage = language === en ? fr : en;
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage === en ? 'en' : 'fr');

        const experienceInNewLang = newLanguage.projet[0].experiences.find(
            (exp) => exp.id === selectedExperience.id
        );
        setSelectedExperience(experienceInNewLang);
    };

    const handleSelect = (title) => {
        const experience = language.projet[0].experiences.find(exp => exp.titre === title);
        setSelectedExperience(experience);
    };

    const handleNextExperience = () => {
        const currentIndex = language.projet[0].experiences.findIndex(exp => exp.titre === selectedExperience.titre);
        const nextIndex = (currentIndex + 1) % language.projet[0].experiences.length;
        setSelectedExperience(language.projet[0].experiences[nextIndex]);
    };

    const [showAlert, setShowAlert] = useState(false);
    const emailAddress = 'me.mathysher@gmail.com';

    const handleCopy = () => {
        navigator.clipboard.writeText(emailAddress)
            .then(() => {
                setShowAlert(true);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };
    return (
        <>
            <CustomCursor></CustomCursor>
            <Header language={language}></Header>
            <Section  id={'top-section'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection :'column'}}>
                <button onClick={toggleLanguage}>
                    {language === en ? 'EN ' : 'FR '}
                    <i className="fa-solid fa-shuffle" style={{margin: '0 10px'}}></i>
                    {language === en ? 'FR' : 'EN'}
                </button>
                <h2 style={{zIndex:'2', margin: '5px 50px', fontWeight: 400, animation: 'tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both'}}>
                    <span className="highlighted-text">{language.accueil[0].titre}</span>
                </h2>
                <p>{language.accueil[0].texte}</p>
                <Button variant={'fond'} locate={'cards'} style={{padding: '5px'}}>{language.reste[0].bouton[0].decouvrir}</Button>
                <img src={barNoir} alt="" />
            </Section>
            <Section id='cards' style={{display:'flex'}}>
                <h3 style={{fontWeight: 500}}><span className="highlighted-text">{language.connaissance[0].titre}</span></h3>
                <div className={'cards-container'}>
                    <Cards
                        title={language.connaissance[0].card1[0].title}
                        text={language.connaissance[0].card1[0].text}
                        underligned={language.connaissance[0].card1[0].underligned}
                    >
                        <Button locate={'experience-section'} variant={'contour'}>{language.reste[0].bouton[0].decouvrir}</Button>
                    </Cards>
                    <Cards
                        title={language.connaissance[0].card2[0].title}
                        text={language.connaissance[0].card2[0].text}
                        underligned={language.connaissance[0].card2[0].underligned}
                    >
                        <Button locate={'experience-section'} variant={'contour'}>{language.reste[0].bouton[0].decouvrir}</Button>
                    </Cards>
                    <Cards
                        title={language.connaissance[0].card3[0].title}
                        text={language.connaissance[0].card3[0].text}
                        underligned={language.connaissance[0].card3[0].underligned}
                    >
                        <Button locate={'experience-section'} variant={"contour"}>{language.reste[0].bouton[0].decouvrir}</Button>
                    </Cards>
                </div>
            </Section>
            <Section id={'experience-section'} style={{paddingTop: '10%', overflow: 'visible'}}>
                <h3><span style={{fontWeight: 500}} className="highlighted-text">{language.projet[0].titre}</span></h3>
                <div className="row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="selector-container" style={{overflow:'hidden'}}>
                        {language.projet[0].experiences.map((experience) => (
                            <Selector
                                key={experience.id}
                                titre={experience.titre}
                                onSelect={handleSelect}
                                isSelected={selectedExperience && selectedExperience.titre === experience.titre}
                            />
                        ))}
                        <Button onClick={handleNextExperience} variant={'fond'} style={{marginLeft: 'auto'}}><img src={whiteArrow} alt=""/></Button>
                    </div>
                    {selectedExperience && (
                        <Experience
                            image={selectedExperience.image}
                            titre={selectedExperience.titre}
                            texte={selectedExperience.texte}
                            insta={selectedExperience.insta}
                            site={selectedExperience.site}
                            date={selectedExperience.date}
                        />
                    )}
                </div>
            </Section>
            <Section id={'gallery-section'} style={{display: 'flex'}}>
                <Gallery></Gallery>
                <div style={{width:'100%', display: 'flex', justifyContent:'flex-end', gap:'20px',marginBottom: '20px'}}>
                    <Button href={'https://www.cosmos.so/itsmout'} variant={'contour'}>{language.reste[0].bouton[0].cosmos}</Button>
                    <Button href={'https://www.tiktok.com/@its_mout'} variant={'fond'}>{language.entete[0].bouton4}</Button>
                </div>
                <img src={barNoir} id={'middle-bar'} alt="" style={{transform:'rotate(180deg)' ,width: 'auto', height: '100%'}}/>
            </Section>
            <Section id={"about-section"} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection :'column'}}>
                <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection :'column', padding: '2% 0', border: '1px black solid', borderRadius: '16px', boxShadow: '10px 5px 5px black' }} className="frame">
                    <h3 style={{fontWeight: 500, color: 'black', backgroundColor: '#CFCCBE', borderRadius: '16px', margin: '20px 0', textAlign: 'center'}}>
                        <span className="highlighted-text">{language.apropos[0].titre}</span>
                    </h3>
                    <p style={{
                        fontWeight: '400',
                        transition: 'opacity 0.5s ease-in-out'
                    }}>
                        {language.apropos[0].texte[0].part1}
                        <br/><br/>
                        {language.apropos[0].texte[1].part2}
                        <br/><br/>
                        {language.apropos[0].texte[2].part3}
                        <br/><br/>
                        {language.apropos[0].texte[3].part4}
                    </p>
                    <div style={{width: '80%', display: 'flex', justifyContent: 'flex-end'}}>
                        <p style={{width: 'min-content'}}>Mout.</p>
                    </div>
                </div>
            </Section>
            <Section id={'contact-section'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection :'column', width:'100%', height: '100%', margin:'0 auto', padding: '2% 0'}}>
                <img src={barNoir} alt="" style={{ width: '95vw', height: '100%', margin: '0 50px'}}/>
                <div className="row" style={{display: 'flex', margin: '0 50px'}}>
                    <h3><span style={{fontSize: '48px', fontWeight: 500, writingMode: 'vertical-rl', transform: 'rotate(180deg)'}} className="highlighted-text">{language.contact[0].titre}</span></h3>
                    <p>
                        {language.contact[0].texte[0].part1}
                        <br/><br/>
                        {language.contact[0].texte[1].part2}
                    </p>
                </div>
                <div className="wrapper">
                    <div className="column">
                        <Button href={'https://github.com/Le-Mouton'}><i className="fa-brands fa-github"></i><span className={'tag'}>Le-Mouton</span></Button>
                        <Button href={'https://www.instagram.com/its_mout'}><i className="fa-brands fa-instagram"></i><span className={'tag'}>@its_mout</span></Button>
                        <Button href={'https://www.tiktok.com/@its_mout'}><i className="fa-brands fa-tiktok"></i><span className={'tag'}>@its_mout</span></Button>
                        <Button href={'https://www.twitch.tv/itsmoutsubscribe'}><i className="fa-brands fa-twitch"></i><span className={'tag'}>@itsmoutsubscribe</span></Button>
                        <Button href={'https://www.youtube.com/@its.mout.subscribe'}><i className="fa-brands fa-youtube"></i><span className={'tag'}>@its.mout.subscribe</span></Button>
                        <Button onClick={handleCopy}><i className="fa-solid fa-envelope"></i><span className={'tag'}>me.mathysher@gmail.com</span></Button>
                    </div>
                    <div className="column">
                        <Button href={'https://github.com/Le-Mouton'}><i className="fa-brands fa-github"></i><span className={'tag'}>Le-Mouton</span></Button>
                        <Button href={'https://www.instagram.com/its_mout'}><i className="fa-brands fa-instagram"></i><span className={'tag'}>@its_mout</span></Button>
                        <Button href={'https://www.tiktok.com/@its_mout'}><i className="fa-brands fa-tiktok"></i><span className={'tag'}>@its_mout</span></Button>
                        <Button href={'https://www.twitch.tv/itsmoutsubscribe'}><i className="fa-brands fa-twitch"></i><span className={'tag'}>@itsmoutsubscribe</span></Button>
                        <Button href={'https://www.youtube.com/@its.mout.subscribe'}><i className="fa-brands fa-youtube"></i><span className={'tag'}>@its.mout.subscribe</span></Button>
                        <Button onClick={handleCopy}><i className="fa-solid fa-envelope"></i><span className={'tag'}>me.mathysher@gmail.com</span></Button>
                    </div>
                    <div className="column">
                        <Button href={'https://github.com/Le-Mouton'}><i className="fa-brands fa-github"></i><span className={'tag'}>Le-Mouton</span></Button>
                        <Button href={'https://www.instagram.com/its_mout'}><i className="fa-brands fa-instagram"></i><span className={'tag'}>@its_mout</span></Button>
                        <Button href={'https://www.tiktok.com/@its_mout'}><i className="fa-brands fa-tiktok"></i><span className={'tag'}>@its_mout</span></Button>
                        <Button href={'https://www.twitch.tv/itsmoutsubscribe'}><i className="fa-brands fa-twitch"></i><span className={'tag'}>@itsmoutsubscribe</span></Button>
                        <Button href={'https://www.youtube.com/@its.mout.subscribe'}><i className="fa-brands fa-youtube"></i><span className={'tag'}>@its.mout.subscribe</span></Button>
                        <Button onClick={handleCopy}><i className="fa-solid fa-envelope"></i><span className={'tag'}>me.mathysher@gmail.com</span></Button>
                    </div>
                </div>
            </Section>
            {showAlert && (
                <CustomAlert
                    message={language.reste[0].alert}
                    onClose={() => setShowAlert(false)}
                />
            )}
            <Footer language={language}></Footer>
        </>
    );
}

export default App;